/*
 * @Author: zhang·xiao
 * @Date: 2022-12-10 11:04:52
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2023-01-09 20:09:52
 * @Description: 描述文件功能
 */
import Editor  from "./edit"

export default Editor
