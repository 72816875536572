<template>
  <div>      
  <Editor />
  </div>
</template>

<script>
import { Editor } from "@formEngine/index"
export default {
  name: "Login",
  components: { Editor },
  data() {
    return {
      formData: {},
      uiSchema: {},
      errorSchema:{},
      customFormats: null,
      formFooter: {
        show: true
      },
      formProps: {
        layoutColumn: 3,
        labelWidth: "100px",
        labelSuffix: null,
        isMiniDes: true
      }
    }
  },
  computed: {
  },
  methods: {
    handleFormMounted() {
      console.log('表单加载完成------', this.formData)
    },
    handleDataChange() {
      console.log('表单数据改变------', this.formData)
    },
    handleCancel() {
      console.log('表单提交取消------', this.formData)
    },
    handleSubmit() {
      console.log('表单确认提交------', this.formData)
    }
  },
};
</script>

<style lang="less" scoped>
.common-layout {
  .top {
    text-align: center;
    .header {
      padding: 0 0 0 40px;
      height: 44px;
      line-height: 44px;
      display: flex;
      justify-items: center;
      align-items: center;
      a {
        text-decoration: none;
      }
      .logo {
        height: 44px;
        vertical-align: top;
        margin-right: 16px;
      }
      .logo-name {
        font-size: 24px;
        color: #333333;
        letter-spacing: 0;
        text-align: center;
        font-weight: 600;
        padding-left: 10px;
      }
      .title {
        font-size: 33px;
        color: @title-color;
        font-weight: 600;
        position: relative;
        top: 2px;
      }
    }
    .desc {
      font-size: 14px;
      color: @text-color-second;
      margin-top: 12px;
      margin-bottom: 40px;
    }
  }
  .login {
    text-align: center;
    position: absolute;
    right: 200px;
    width: 490px;
    height: 600px;
    background: #ffffff;
    margin: 70px 0 0 0;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    .login-title {
      margin: 54px 0 0 40px;
      width: 76px;
      height: 50px;
      font-size: 18px;
      color: #333333;
      letter-spacing: 0;
      line-height: 50px;
      font-weight: 600;
      border-bottom: 2px solid #bcc0c8;
    }
    .project-name {
      font-size: 26px;
      color: #333330;
      letter-spacing: 0;
      text-align: center;
      font-weight: 600;
      margin: 55px 0;
    }
    .login-input {
      width: 400px;
      height: 50px;
      border: 1px solid rgba(196, 204, 219, 1);
      border-radius: 4px;
    }
  }
}
</style>
